import type { Message } from "@circle-react/types/CommunityInbox/Message";
import { Avatar } from "./Avatar";
import { ContentBlock } from "./ContentBlock";

export const MessageContentItem = ({ message }: { message: Message }) => {
  const {
    id,
    body,
    author_type: authorType,
    community_bot_profile: communityBotProfile,
    community_member: communityMember,
    created_at: createdAt,
    updated_at: updatedAt,
    sources,
  } = message;
  const elementKey = `${id}-${updatedAt}`;
  const isUserMessage = authorType === "community_member";
  const authorName = isUserMessage
    ? (communityMember?.name ?? "")
    : (communityBotProfile?.name ?? "");
  return (
    <div className="px-4 py-3" id={id.toString()} key={elementKey}>
      <div className="flex w-full items-start justify-start gap-3">
        <div className="pt-0.5">
          <Avatar message={message} />
        </div>
        <ContentBlock
          isUserMessage={isUserMessage}
          authorName={authorName}
          timestamp={createdAt}
          body={body}
          sources={sources}
        />
      </div>
    </div>
  );
};
