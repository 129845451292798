import { t } from "@/i18n-js/instance";
import { SharedFilters } from "@circle-react-shared/MemberFilters";

const {
  SpaceGroupsFilterV2,
  SpacesFilter,
  StatusFilter,
  MemberTagsFilter,
  PaywallsFilter,
  EventsFilter,
  HasPastDueSubscriptionFilter,
  JoinedCommunityDaysAgoFilter,
  LastLoggedInDaysAgoFilter,
  AvatarSet,
  ActivityScoreFilter,
  HeadlineFilter,
  NameFilter,
  LocationFilter,
  GeoLocationFilter,
  BioFilter,
  EmailFilter,
  InvitationLinksFilter,
  LevelFilter,
  EmailMarketingFilter,
  MemberFilter,
  InvitationStatusFilter,
  InvitedAtDaysAgoFilter,
  RoleFilter,
  SegmentFilter,
  AccessGroupsFilter,
} = SharedFilters;

export const geoLocationFilter = {
  name: "geo_location",
  component: GeoLocationFilter,
  label: t("members_directory.header.search.filters.geo_location"),
};

export const nameFilter = {
  name: "name",
  component: NameFilter,
  label: t("members_directory.header.search.filters.name"),
};

export const emailFilter = {
  name: "email",
  component: EmailFilter,
  label: t("members_directory.header.search.filters.email"),
};

export const subscribedToMarketingEmailsFilter = {
  name: "subscribed_to_marketing_emails",
  component: EmailMarketingFilter,
  label: t("members_directory.header.search.filters.email_marketing"),
};

export const memberFilter = {
  name: "member",
  component: MemberFilter,
  label: t("members_directory.header.search.filters.member"),
};

export const invitationStatusFilter = {
  name: "invitation_status",
  component: InvitationStatusFilter,
  label: t("members_directory.header.search.filters.invitation_status.title"),
};

export const invitedAtDaysAgoFilter = {
  name: "invited_at_days_ago",
  component: InvitedAtDaysAgoFilter,
  label: t("members_directory.header.search.filters.invited_at_days_ago"),
};

export const invitationLinkIdsFilter = {
  name: "invitation_link_ids",
  component: InvitationLinksFilter,
  label: t(
    "members_directory.header.search.filters.invitation_link_ids.singular_title",
  ),
};

export const tagsFilter = {
  name: "tags",
  component: MemberTagsFilter,
  label: t("members_directory.header.search.filters.tags.singular_title"),
};

export const spacesFilter = {
  name: "spaces",
  component: SpacesFilter,
  label: t("members_directory.header.search.filters.spaces.singular_title"),
};

export const spaceGroupsFilter = {
  name: "space_groups",
  component: SpaceGroupsFilterV2,
  label: t(
    "members_directory.header.search.filters.space_groups.singular_title",
  ),
};

export const onlineStatusFilter = {
  name: "online_status",
  component: StatusFilter,
  label: t("members_directory.header.search.filters.online_status"),
};

export const bioFilter = {
  name: "bio",
  component: BioFilter,
  label: t("members_directory.header.search.filters.bio"),
};

export const headlineFilter = {
  name: "headline",
  component: HeadlineFilter,
  label: t("members_directory.header.search.filters.headline"),
};

export const locationFilter = {
  name: "location",
  component: LocationFilter,
  label: t("members_directory.header.search.filters.location"),
};

export const paywallsFilter = {
  name: "paywalls",
  component: PaywallsFilter,
  label: t("members_directory.header.search.filters.paywalls.singular_title"),
};

export const eventsFilter = {
  name: "events",
  component: EventsFilter,
  label: t("members_directory.header.search.filters.events.singular_title"),
};

export const hasPastDueSubscriptionFilter = {
  name: "has_past_due_subscription",
  component: HasPastDueSubscriptionFilter,
  label: t("members_directory.header.search.filters.has_past_due_subscription"),
};

export const joinedCommunityDaysAgoFilter = {
  name: "joined_community_days_ago",
  component: JoinedCommunityDaysAgoFilter,
  label: t("members_directory.header.search.filters.joined_community_days_ago"),
  listLabel: t(
    "members_directory.header.search.filters.joined_community_days_ago_available",
  ),
};

export const lastLoggedInDaysAgoFilter = {
  name: "last_logged_in_days_ago",
  component: LastLoggedInDaysAgoFilter,
  label: t("members_directory.header.search.filters.last_logged_in_days_ago"),
};

export const isAvatarSetFilter = {
  name: "is_avatar_set",
  component: AvatarSet,
  label: t("members_directory.header.search.filters.avatar_set"),
};

export const activityScoreFilter = {
  name: "activity_score",
  component: ActivityScoreFilter,
  label: t("members_directory.header.search.filters.activity_score"),
};

export const levelFilter = {
  name: "level",
  component: LevelFilter,
  label: t("members_directory.header.search.filters.level"),
};

export const roleFilter = {
  name: "role",
  component: RoleFilter,
  label: t("members_directory.header.search.filters.role"),
};

export const segmentFilter = {
  name: "segment_id",
  component: SegmentFilter,
  label: t("members_directory.header.search.filters.segments.singular_title"),
};

export const accessGroupsFilter = {
  name: "access_group_ids",
  component: AccessGroupsFilter,
  label: t(
    "members_directory.header.search.filters.access_groups.singular_title",
  ),
};
