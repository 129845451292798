import { useEffect, useRef } from "react";
import { truncate } from "lodash";
import { t } from "@/i18n-js/instance";
import { Form } from "@circle-react-shared/uikit/Form";
import { TiptapEditor } from "@circle-react-shared/uikit/TipTap";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { MenuBar } from "./MenuBar";

interface MessageBoxProps {
  onSubmit: (value: any) => void;
  isProcessing?: boolean;
  agentName?: string;
  isDms: boolean;
}

interface TiptapContent {
  body: object;
  pause_ai_minutes?: number;
}

const DEFAULT_VALUES = {
  body: {
    type: "doc",
    content: [],
  },
  pause_ai_minutes: null,
};

export const MessageBox = ({
  onSubmit,
  isProcessing,
  agentName,
  isDms,
}: MessageBoxProps) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor: any = editorRef.current;
    if (!editor) return;
    if (isProcessing) {
      editor.view.dom.blur();
    } else {
      editor.view.dom.focus();
    }
  }, [isProcessing]);

  return (
    <Form onSubmit={onSubmit} defaultValues={DEFAULT_VALUES}>
      {({ getValues }) => {
        const handleSubmit = (content: TiptapContent) => {
          const { pause_ai_minutes } = getValues();
          if (pause_ai_minutes) {
            onSubmit({ body: content, pause_ai_minutes: pause_ai_minutes });
          } else {
            onSubmit({ body: content });
          }
        };
        return (
          <div className="group relative">
            <TiptapEditor
              key={`message-box-${agentName}`}
              menuBarComponent={MenuBar}
              placeholder={t("community_inbox.message_bot", {
                agent_name: truncate(agentName, { length: 40 }),
              })}
              className="!mb-2 !flex-row items-center justify-between !p-0 !py-[0.1875rem] !pr-4"
              messageBoxClassName="grow"
              editorClassName="px-4 py-3"
              onSubmit={handleSubmit}
              disabled={isProcessing}
              editorRef={editorRef}
              menubarProps={{ isDms }}
              shouldShowPreviewBlocks={false}
              shouldDisableEntities
              shouldDisableMentions
            />
            {isProcessing && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="invisible mt-[-50px] rounded-lg bg-black px-3 py-2 group-hover:visible">
                  <Typography.LabelSm weight="font-medium" color="text-white">
                    {t("community_inbox.please_wait_message")}
                  </Typography.LabelSm>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Form>
  );
};
