import { Suspense } from "react";
import { lazyWithRetry } from "@circle-react/helpers/lazyRetry";
import { PageLoader } from "@circle-react/pages/PageLoader";

const PushNotificationsComponent = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PushNotificationsDashboardPage" */ "@circle-react/components/SettingsApp/PushNotificationsDashboard"
    ),
);

export const PushNotificationsDashboardPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PushNotificationsComponent />
  </Suspense>
);
